/* IMPORTANT: CHANGE THIS FILE ONLY IN FLOATWORK_ TEMPLATE */

// BASIC COLRS
$black: #000;
$black-contrast: #000;
$white: #fff;
$white-contrast: #fff;

// PRIMARY TEXT COLOR (IDEALLY DARK COLOR)
$color-primary: #434343;
$color-primary-contrast: $black;
// CI COLOR
$color-secondary: #178fcb;
$color-secondary-contrast: $black;
// SECOND CI COLOR
$color-tertiary: #3AA;
$color-tertiary-contrast: #3AA;

$image_path: '../../images/';
$color-link: $color-secondary;
$color-link-contrast: #0000FF;
$color-link-hover: #666;
$color-link-hover-contrast: $white;
$color-error: #e00;
$color-error-contrast: #e00;

$blue-075: #51ABD8;
$blue-075-contrast: $black;
$cyan-05: $white;
$cyan-05-contrast: #7EC7C3;
$lightcyan: $white;
$lightcyan-contrast: #285a63;
$cyan: #285a63;
$cyan-contrast: $black;
$darkcyan: #285a63;
$darkcyan-contrast: $black;
$darkblue-05: #7E97BF;
$darkblue-05-contrast: $white;
$darkblue: #003181;
$darkblue-contrast: $black;
$darkgrey: #6d6d6d;
$darkgrey-contrast: $black;
$green-05: #CECC3F;
$green-05-contrast: $black;
$green: #bebc00;
$green-contrast: $black;
$iconblue: #41aad6;
$iconblue-contrast: $black;
$icondarkgrey: #918f8f;
$icondarkgrey-contrast: $black;
$iconlightgrey: #c8c7c7;
$iconlightgrey-contrast: $white;

$lightblue-05: #7ED9ED;
$lightblue-05-contrast: #7ED9ED;
$lightblue: #00b5dd;
$lightblue-contrast: #00b5dd;
$lighterblue: #6eafd9;
$lighterblue-contrast: $black;
$lightestgrey: #9d9d9d;
$lightestgrey-contrast: $black;
$lightgrey: #e5e5e5;
$lightgrey-contrast: $white;
$middleblue: #0d539e;
$middleblue-contrast: $white;
$etcolor: #f6d701;
$etcolor-contrast: $black;
$pccolor: #ccc;
$pccolor-contrast: $black;
$twitterblue: #178fcb;
$facebookblue: #4267b2;
$facebookblue-contrast: $black;
$instagram: #d31164;
$instagram-contrast: $black;
$twitterblue-contrast: $black;
$yellow-075: #FFDD3F;
$yellow-075-contrast: $white;
$yellow2: #f6d700;
$yellow2-contrast: $white;
$lightyellow: #e0d8a3;
$lightyellow-contrast: $white;
$yellow: #ffd200;
$yellow-contrast: $black;
$youtubered: #ff0000;
$youtubered-contrast: #ff0000;

//colors for dossiers
$lightgreen: #b9dcd8;
$lightgreen-contrast: $black;
$smudgegreen: #46a9a4;
$smudgegreen-contrast: $black;
$smudgewhite: #fbfafb;
$smudgewhite-contrast: $black;
$smudgeyellow: #ede2ab;
$smudgeyellow-contrast: $black;
$smudgeblue: #c0d5df;
$smudgeblue-contrast: $black;
$smudgegrey: #acacac;
$smudgegrey-contrast: $black;
$peach: #eacdc5;
$peach-contrast: $black;

// $colors: ('primary' $color-primary), ('secondary' $color-secondary), ('tertiary' $color-tertiary), ('link' $color-link), ('link-hover' $color-link-hover), ('error' $color-error), ('white' $white), ('black' $black), ('lightgrey' $lightgrey), ('lightestgrey' $lightestgrey), ('darkgrey' $darkgrey), ('lightyellow' $lightyellow), ('yellow' $yellow), ('green' $green), ('cyan' $cyan), ('darkcyan' $darkcyan), ('lightcyan' $lightcyan), ('iconlightgrey' $iconlightgrey), ('icondarkgrey' $icondarkgrey), ('iconblue' $iconblue), ('lightblue' $lightblue), ('cyan-05' $cyan-05), ('darkblue-05' $darkblue-05), ('lightblue-05' $lightblue-05), ('green-05' $green-05)
$colors: ('primary' $color-primary $color-primary-contrast), ('secondary' $color-secondary $color-secondary-contrast), ('tertiary' $color-tertiary $color-tertiary-contrast), ('link' $color-link $color-link-contrast), ('link-hover' $color-link-hover $color-link-hover-contrast), ('error' $color-error $color-error-contrast), ('white' $white $white-contrast), ('black' $black $black-contrast), ('blue-075' $blue-075 $blue-075-contrast), ('cyan-05' $cyan-05 $cyan-05-contrast), ('lightcyan' $lightcyan $lightcyan-contrast), ('cyan' $cyan $cyan-contrast), ('darkcyan' $darkcyan $darkcyan-contrast), ('darkblue-05' $darkblue-05 $darkblue-05-contrast), ('darkblue' $darkblue $darkblue-contrast), ('darkgrey' $darkgrey $darkgrey-contrast), ('green-05' $green-05 $green-05-contrast), ('green' $green $green-contrast), ('iconblue' $iconblue $iconblue-contrast), ('icondarkgrey' $icondarkgrey $icondarkgrey-contrast), ('iconlightgrey' $iconlightgrey $iconlightgrey-contrast), ('lighterblue' $lighterblue $lighterblue-contrast), ('lightblue-05' $lightblue-05 $lightblue-05-contrast), ('lightblue' $lightblue $lightblue-contrast), ('lightestgrey' $lightestgrey $lightestgrey-contrast), ('lightgrey' $lightgrey $lightgrey-contrast), ('middleblue' $middleblue $middleblue-contrast), ('twitterblue' $twitterblue $twitterblue-contrast), ('facebookblue' $facebookblue $facebookblue-contrast), ('instagram' $instagram $instagram-contrast), ('yellow-075' $yellow-075 $yellow-075-contrast), ('yellow2' $yellow2 $yellow2-contrast), ('lightyellow' $lightyellow $lightyellow-contrast), ('yellow' $yellow $yellow-contrast), ('youtubered' $youtubered $youtubered-contrast), ('lightgreen' $lightgreen $lightgreen-contrast), ('peach' $peach $peach-contrast), ('smudgegreen' $smudgegreen $smudgegreen-contrast),  ('smudgewhite' $smudgewhite $smudgewhite-contrast),  ('smudgeyellow' $smudgeyellow $smudgeyellow-contrast), ('smudgeblue' $smudgeblue $smudgeblue-contrast), ('smudgegrey' $smudgegrey $smudgegrey-contrast);

input, textarea, button, select, label, a {
	-webkit-tap-highlight-color: rgba(0,0,0,0); }

a {
	color: inherit; }

@each $color in $colors {
	$current-selector: nth($color, 1);
	$current-color: nth($color, 2);
	$current-contrast-color: nth($color, 3);

	%#{$current-selector}_bg {
		background: ($current-color);

		.contrast &,  &.contrast {
			background: ($current-contrast-color) !important; } }

	%#{$current-selector}_color {
		color: ($current-color);

		.contrast &,  &.contrast {
			color: ($current-contrast-color) !important; } }

	%#{$current-selector}_fill {
		fill: ($current-color);

		.contrast &,  &.contrast {
			fill: ($current-contrast-color) !important; } }

	%#{$current-selector}_border {
		border-color: ($current-color);

		.contrast &,  &.contrast {
			border-color: ($current-contrast-color) !important; } }

	.#{$current-selector}_color {
		@extend %#{$current-selector}_color; }

	.#{$current-selector}_bg {
		@extend %#{$current-selector}_bg; } }

// 	#main
// 		.secondary_bg, &.secondary_bg
// 			@extend %white_bg

// 		.white_color .secondary_bg, .secondary_bg %white_color
// 			@extend %black_color

// svg
// 	.icon, &:hover .icon
// 		@extend %black_fill

.icon_lightgrey {
	fill: $lightgrey;

	.contrast & {
		fill: $black; }

	.contrast svg:hover {
		fill: $black; } }

.icon_darkgrey {
	@extend %icondarkgrey_fill; }

.icon_blue {
	@extend %iconblue_fill; }

.icon_lightgrey {
	svg:hover & {
		@extend %lightgrey_fill;
		fill: rgba($lightgrey,0.9); } }

.icon_darkgrey {
	svg:hover & {
		@extend %darkgrey_fill;
		fill: rgba($darkgrey,0.9); } }

.icon_blue {
	svg:hover & {
		@extend %link_fill;
		fill: rgba($color-link,0.9); } }

.contrast svg:hover .icon_lightgrey {
	fill: $black !important; }

.pattern_bg {
	background-image: url($image_path+'pattern.png'); }

.pattern2_bg {
	background-image: url($image_path+'pattern2.png'); }

.contrast {
	.pattern_bg, .pattern2_bg {
		background: $black; } }

.pattern_bg, .pattern2_bg {
	background-repeat: repeat; }
