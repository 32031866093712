/* IMPORTANT: CHANGE THIS FILE ONLY IN FLOATWORK_ TEMPLATE */

/* PATHS */
$image_path:    							'../../images/';

/* BREAKPOINTS */
$smartphone:    							767px;
$tablet_p:    								768px;
$tablet_l:    								1120px;
$desktop:    								1280px;

/* FONT SIZES */
$html-font-size:    						62.5%;

$base-font-size:    						22px;
$base-line-height:     						32px;
$base-text-transform:    					none;
$base-letter-spacing:    					0.025em;

$small-font-size:    						16px;
$small-line-height:    						24px;


$font-size-h1:    							100px;
$line-height-h1:    						110px;
$text-transform-h1:    						none;
$letter-spacing-h1:    						0.05em;

$font-size-h2:    							38px;
$line-height-h2:    						50px;
$text-transform-h2:    						none;
$letter-spacing-h2:    						0.05em;

$font-size-h3:    							32px;
$line-height-h3:    						38.4px;
$text-transform-h3:    						none;
$letter-spacing-h3:    						0.05em;

$font-size-h4:    							22px;
$line-height-h4:    						32px;
$text-transform-h4:    						none;
$letter-spacing-h4:    						0.05em;

$font-size-h5:    							20px;
$line-height-h5:    						26px;
$text-transform-h5:    						none;
$letter-spacing-h5:    						0.05em;

$font-size-h6:    							18px;
$line-height-h6:    						26px;
$text-transform-h6:    						none;
$letter-spacing-h6:    						0.025em;

$font-size-header-menu:    					16px;
$line-height-header-menu:    				24px;
$text-transform-header-menu:    			uppercase;
$letter-spacing-header-menu:    			0.1em;

$font-size-header-submenu:    				17px;
$line-height-header-submenu:    			24px;
$text-transform-header-submenu:    			none;
$letter-spacing-header-submenu:    			0em;

$font-size-header-menu-mobile:    			17px;
$line-height-header-menu-mobile:    		21px;
$text-transform-header-menu-mobile:    		uppercase;
$letter-spacing-header-menu-mobile:    		0.05em;

$font-size-header-submenu-mobile:    		14px;
$line-height-header-submenu-mobile:    		19px;
$text-transform-header-submenu-mobile:    	none;
$letter-spacing-header-submenu-mobile:    	0.03em;

$font-size-footer-menu:    					16px;
$line-height-footer-menu:    				26px;
$text-transform-footer-menu:    			none;
$letter-spacing-footer-menu:    			0.1em;

$font-size-footer-submenu:    				10px;
$line-height-footer-submenu:    			12px;
$text-transform-footer-submenu:    			none;
$letter-spacing-footer-submenu:    			0em;

/* GRID */
$grid-width:    							1220px;
$gutter-width:    							30px;
$outer-gutter-width:    					30px;

/* DESKTOP */
$header-height:    							250px;
$logo-width:    							122px;
$logo-height:    							107px;

/* MOBILE */
$mobile-logo-width:    						20px;
$mobile-menu-button-outer-padding-ipad:    	20px;
$mobile-menu-button-outer-padding-phone:    20px;
$mobile-bar-height:    						60px;
$mobile-menu-button-width:     				30px;
$mobile-menu-bar-height:     				3px;
$mobile-menu-bar-gutter-vertical:    		4px;

/* FORMS */
$input-height:     							33px;
$input-height2:     						40px;
$input-height3:     						38px;

/* SLIDESHOW */
$arrow-width:     							24px;
$arrow-height:     							40px;
$arrow-width-mobile:     					$arrow-width*0.5;
$arrow-height-mobile:     					$arrow-height*0.5;
