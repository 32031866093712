/* IMPORTANT: CHANGE THIS FILE ONLY IN FLOATWORK_ TEMPLATE */

// panel background
$color-panel-bg: #f1f1f1;
$color-menu-bg: #333333;
$color-menu-bg-hover: #111111;

body {
	background: $white; }

#loginform {
	background-color: $color-secondary; }

.login label {
	color: $white; }

.wp-core-ui .button-primary {
	background: #333;
	border-color: transparent;
	box-shadow: none;
	border-radius: 0;
	text-shadow: none; }

.wp-core-ui .button-primary.focus, .wp-core-ui .button-primary.hover, .wp-core-ui .button-primary:focus, .wp-core-ui .button-primary:hover {
	background: #111111;
	border-color: #444444; }

.login #backtoblog a, .login #nav a {
	text-decoration: none;
	color: #555d66; }

body.login div#login h1 a {
	background-image: url($image-path+'logo.png'); }

.login h1 a {
	background-size: $logo-width * 0.7 auto;
	background-position: center top;
	background-repeat: no-repeat;
	width: $logo-width * 0.7;
	height: $logo-height * 0.7; }

.login #login_error, .login .message {
	border-color: $color-secondary; }

.login #backtoblog a:hover, .login #nav a:hover, .login h1 a:hover {
	color: $color-secondary; }

a:focus {
	box-shadow: none; }
